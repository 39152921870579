body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dont-scroll {
  overflow: hidden;
  position: absolute;
}

.ant-menu-sub .ant-menu-item{
  padding-left: 10px !important;
  height: 30px !important;
  line-height: 30px !important;
}

iframe {
  pointer-events: none;
}
